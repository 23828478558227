<template>
  <div id="deployer-form" class="deployer-view">
    <es-select v-model="account" :options="accounts" label="Account" />
    <es-select v-model="region" :options="regions" label="Region" />
    <es-select v-model="inventory" :options="inventories" label="Inventory" />
    <es-button-next theme="classic" v-on:click="handleDeploy">
      Deploy
    </es-button-next>

    <es-button-next theme="classic" v-on:click="handleDestroy">
      Destroy
    </es-button-next>
    <es-button-next theme="classic" v-on:click="getOutputs">
        Outputs
    </es-button-next>
    <es-button-next
      theme="classic"
      v-on:click="getInventory"
      v-bind:disabled="!isInventoryDisabled"
    >
      View Inventory
    </es-button-next>
    <br></br> 
    <es-textarea v-model="info" rows="10" :disabled="false" height="200px">
    </es-textarea>
    <br></br> 
    <form id="upload-form">
      <input id="file-field" name="file" type="file" @change="onFileChange" />
    </form>
    <es-button-next theme="classic" v-on:click="uploadFile">
      Upload
    </es-button-next>
  </div>
</template>

<script>
import axios from "axios";
import EsButtonNext from "@EditShareCo/es-button-next";
import EsSelect from "@EditShareCo/es-select";
import { EsTextarea } from "@EditShareCo/es-input";
import Vue from "vue";
import { mapState } from "vuex";

// TODO Retrieve this from Environment variables
// const API_URL = "http://localhost:5000";
// This one is for ecd-flask-dev1 instance in Elastic Beanstalk
// const API_URL = "http://ecd-flask-dev1.eba-admpcee9.us-east-1.elasticbeanstalk.com";
const API_URL = process.env.VUE_APP_API_URL

export default Vue.extend({
  name: "DeployerForm",
  components: { EsButtonNext, EsSelect, EsTextarea },
  mounted: function () {
    this.getRegions();
    this.getOrganizations();
    this.getInventories();
    this.getAccounts();
  },
  data() {
    return {
      file: "",
      filename: "",
      uploadedFile: "",
      fullAccess: true,
      test: "",
      resource_prefix: "",
      account: "",
      accounts: [
        { label: "dev", value: "dev" },
        { label: "qa", value: "qa" },
        { label: "presales", value: "presales" },
      ],
      aws_access_key_id: "",
      aws_secret_access_key: "",
      pem_file_name: "",
      organization: "",
      organizations: [],
      workspace: "",
      workspaces: [],
      create_workspace: "",
      region: "",
      regions: [
        // { label: "first", value: 1 },
        // { label: "second", value: 2 }
      ],
      info: "",
      inventory: "",
      inventories: [],
      license_id: "",
      submitting: false,
      error: false,
      success: false,
      roles: [
        { text: "editshare", value: "editshare" },
        { text: "partner", value: "partner" },
        { text: "customer", value: "customer" },
        { text: "marketplace", value: "marketplace" },
      ],
      role: "editshare",
      users_map: {
        editshare: [{ text: "general", value: "general" }],
        partner: [
          { text: "tyrell", value: "tyrell" },
          { text: "humancircuit", value: "humancircuit" },
        ],
        customer: [{ text: "deptofstate", value: "deptofstate" }],
        marketplace: [{ text: "general", value: "general" }],
      },
      users: [],
      userold: "",
    };
  },
  myService: null,
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createFile(files[0]);
    },
    //View uploaded image
    createFile(file) {
      this.filename = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedFile = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    uploadFile: function () {
      console.log("File upload clicked");
      // this.$socket.emit('upload-file', this.file)  // TODO
      var params = new FormData();
      params.append("file", this.uploadedFile);
      params.append("filename", this.filename);
      // post the data converted to FormData using Axios to Flask.
      axios.post(`${API_URL}/upload`, params).then(function (response) {
        console.log(response);
      });
      // reload the list of inventory files since a new one may have been created in S3
      this.getInventories();
    },
    getAccounts() {
      console.log("in getAccounts");
    },
    getRegions() {
      console.log("in getRegions");
      const path = API_URL + "/regions";
      axios
        .get(path)
        .then((res) => {
          console.log(res.data["data"]);
          this.regions = res.data["data"];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getInventories() {
      console.log("in getInventories");
      const path = API_URL + "/inventories";
      axios
        .get(path)
        .then((res) => {
          console.log(res.data["data"]);
          this.inventories = res.data["data"];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getOrganizations() {
      console.log("in getOrganizations");
      const path = API_URL + "/organizations";
      axios
        .get(path)
        .then((res) => {
          console.log(res.data["data"]);
          this.organizations = res.data["data"];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getWorkspaces(event) {
      console.log("in getWorkspaces");
      console.log("event: " + event);
      let org_item = this.organizations.find(
        (option) => option.value === event
      );
      let org_name = org_item.text;
      console.log("org_name: " + org_name);
      const path =
        API_URL + "/organizations/" + org_name + "/workspaces";
      axios
        .get(path)
        .then((res) => {
          console.log(res.data["data"]);
          this.workspaces = res.data["data"];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    handleDeploy() {
      console.log("current username", this.user.username);
      this.info = "";
      console.log("in handleDeploy");
      let payload = {};
      payload["inventory"] = this.inventory;
      payload["region"] = this.region;
      payload["account"] = this.account;
      payload["owner"] = this.user.username;

      // let org_item = this.organizations.find(option => option.value === this.organization)
      // let org_name = org_item.text
      // payload['organization'] = org_name
      console.log(payload);
      this.info = "Deploying " + this.inventory + " to " + this.region + " in the " + this.account + " account"
      const path = API_URL + "/efsv/deploy";
      axios
        .post(path, payload, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.status);
          console.log(res.data);
          // console.log('doing fromEntries');
          // let obj = Object.fromEntries(res.data.data);
          // console.log(obj)
          // console.log("doing stringify");
          let jsonString = JSON.stringify(res.data);
          console.log(jsonString);
          this.info = JSON.stringify(res.data, null, 2);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    handleDestroy() {
      this.info = "";
      console.log("in handleDestroy");
      let payload = {};
      payload["inventory"] = this.inventory;
      payload["region"] = this.region;
      payload["account"] = this.account;
      payload["owner"] = this.user.username;
      console.log(payload);
      const path = API_URL + "/efsv/destroy";
      this.info = "Destroying " + this.inventory + " from " + this.region + " in the " + this.account + " account"
      axios
        .post(path, payload, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.status);
          console.log(res.data);
          // console.log("doing fromEntries");
          // let obj = Object.fromEntries(res.data);
          // console.log(obj);
          // console.log("doing stringify");
          let jsonString = JSON.stringify(res.data);
          console.log(jsonString);
          this.info = JSON.stringify(res.data, null, 2);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getOutputs() {
      this.info = "";
      console.log("in getOutputs");
      let payload = {};
      payload["inventory"] = this.inventory;
      payload["region"] = this.region;
      payload["account"] = this.account;
      payload["owner"] = this.user.username;
      console.log(payload);
      const path = API_URL + "/efsv/outputs";
      axios
        .post(path, payload, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.status);
          console.log(res.data);
          let jsonString = JSON.stringify(res.data);
          console.log(jsonString);
          this.info = JSON.stringify(res.data, null, 2);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getInventory() {
      this.info = "";
      console.log("in getInventory");
      let payload = {};
      payload["inventory"] = this.inventory;
      payload["region"] = this.region;
      payload["account"] = this.account;
      payload["owner"] = this.user.username;
      console.log(payload);
      const path = API_URL + "/efsv/display_inventory";
      axios
        .post(path, payload, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.status);
          console.log(res.data);
          let jsonString = JSON.stringify(res.data);
          console.log(jsonString);
          this.info = JSON.stringify(res.data, null, 2);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    doLogin() {
      console.log("in doLogin");
      const path = API_URL + "/profiles";
      axios
        .get(path, { params: { role: this.role, user: this.userold } })
        .then((res) => {
          console.log(res.data["data"]);
          this.inventories = res.data["data"];
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      if (this.role == "partner" || this.role == "marketplace") {
        this.fullAccess = false;
      } else {
        this.fullAccess = true;
      }
    },
    clearStatus() {
      this.success = false;
      this.error = false;
    },
  },
  computed: {
    isInventoryDisabled() {
      return this.inventory.length > 0;
    },
    ...mapState("authentication", {
          user: (state) => state,
    }),
  },
});
</script>

<style scoped>
form {
  margin-bottom: 2rem;
}

.deployer-view {
  margin: 25px;
  width: 700px;
  height: 1000px;
  left: 8px;
  /* outline: dashed 1px orange; */
}

.textarea-view {
  outline: dashed 1px red;
}

.es-button {
  background-color: #004e84;
} /* Blue */

.es-label {
  color: blue;
}

[class*="-message"] {
  font-weight: 500;
}

.error-message {
  color: #d33c40;
}

.success-message {
  color: #32a95d;
}
</style>
