<template>
    <div>
        <br><br>
        <es-select v-model="account" :options="accounts" label="Account" />
        <es-select v-model="region" :options="regions" label="Region" />

        <!-- <h5>Account</h5>
        <Dropdown v-model="selectedAccount" :options="accounts" 
                  optionLabel="label" optionValue="value"
                  placeholder="Select an Account" /> -->

        <Toolbar class="mb-4">
            <template #start>
            </template>
            <template #end>
                <es-button-next theme="classic" v-on:click="startSelectedInstances">
                Start
                </es-button-next>
                <es-button-next theme="classic" v-on:click="getInstances">
                    Refresh
                </es-button-next>
                <es-button-next theme="classic" v-on:click="stopSelectedInstances">
                    Stop
                </es-button-next>
            </template>
        </Toolbar>
        <h5>&nbsp;</h5>
        <!-- https://www.primefaces.org/primevue-v2/#/datatable/selection -->
        <DataTable ref="dt" :value="instances" :selection.sync="selectedInstances" dataKey="instance_id" 
            class="p-datatable-striped p-datatable-gridlines" 
            :resizableColumns="true" autoLayout
            :filters="filters" showGridlines
        >
            <template #header>
                <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                    <h3 class="mb-2 md:m-0 p-as-md-center">Manage Instances</h3>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Search..." />
                    </span>
                </div>
                <h5>&nbsp;</h5>
            </template>

            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
            <Column field="name" header="Name" :sortable="true" style="min-width:12rem"></Column>
            <Column field="public_ip" header="Public IP" :sortable="true" style="min-width:12rem"></Column>
            <Column field="private_ip" header="Private IP" :sortable="true" style="min-width:12rem"></Column>
            <Column field="password" header="Password" :sortable="true" style="min-width:12rem"></Column>
            <Column field="type" header="Instance Type" :sortable="true" style="min-width:12rem"></Column>
            <Column field="state" header="State" :sortable="true" style="min-width:10rem">
                <template #body="slotProps">
                    <div :class="instanceStateClass(slotProps.data)">
                        {{slotProps.data.state}}
                    </div>
                </template>
            </Column>
            <Column field="instance_id" header="Instance Id" :sortable="true" style="min-width:12rem"></Column>
        </DataTable>

	</div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { FilterMatchMode } from 'primevue/api';
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import Column from "primevue/column";
import Toolbar from 'primevue/toolbar';
import Dropdown from 'primevue/dropdown';
import EsButtonNext from "@EditShareCo/es-button-next";
import EsSelect from "@EditShareCo/es-select";
import myfile from "@/shared/myfile";

const API_URL = process.env.VUE_APP_API_URL

export default Vue.extend({
    name: "InstanceManager",
    components: {
        DataTable,
        Button,
        InputText,
        Column,
        Toolbar,
        Dropdown,
        EsButtonNext,
        EsSelect,
    },
    data() {
        return {
            instances: [],
            instance: "",
            selectedInstances: null,
            filters: {},
            regions: [],
            region: "us-east-2",
            accounts: [
                { label: "dev", value: "dev" },
                { label: "qa", value: "qa" },
                { label: "presales", value: "presales" },
            ],
            account: "dev",
            selectedAccount: null,
            filters: {},
        }
    },
    created() {
        this.initFilters();
        // myfile.bar()
    },
    mounted: function () {
        this.getInstances();
        this.getRegions();
    },
    methods: {
        getInstances() {
            console.log("in getInstances");
            const path = API_URL + "/instances";
            axios
                .get(path, { params: { region: this.region, account: this.account } })
                .then((res) => {
                    console.log(res.data["data"]);
                    this.instances = res.data["data"];
                })
                .catch((error) => {
                // eslint-disable-next-line
                    console.error(error);
                });
            // this.instances = [
            //     {"name": "Foo", "instance_id": "foo_id", "state": "Running"},
            //     {"name": "Bar", "instance_id": "bar_id", "state": "Stopped"},
            // ]
        },
        getRegions() {
        console.log("in getRegions");
        const path = API_URL + "/regions";
        axios
            .get(path)
            .then((res) => {
            console.log(res.data["data"]);
            this.regions = res.data["data"];
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        startSelectedInstances() {
            // this next line would remove the selected items from the list, which we don't want to do
            // this.instances = this.instances.filter(val => !this.selectedInstances.includes(val));
            let instance_ids = [];
            for (var i = 0; i < this.selectedInstances.length; i++) { 
                let inst = this.selectedInstances[i]
                console.log("starting instance: " + inst["name"]);
                instance_ids.push(inst["instance_id"]);
            }
            this.selectedInstances = null;
            // this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});

            let payload = {};
            payload["instance_ids"] = instance_ids;
            payload["region"] = this.region;
            payload["account"] = this.account;
            console.log(payload);
            const path = API_URL + "/instances/start";
            axios
                .post(path, payload, {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res.status);
                    console.log(res.data);
                    this.getInstances();
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });

        },
        stopSelectedInstances() {
            // this next line would remove the selected items from the list, which we don't want to do
            // this.instances = this.instances.filter(val => !this.selectedInstances.includes(val));
            let instance_ids = [];
            for (var i = 0; i < this.selectedInstances.length; i++) { 
                let inst = this.selectedInstances[i]
                console.log("stopping instance: " + inst["name"]);
                instance_ids.push(inst["instance_id"]);
            }
            this.selectedInstances = null;
            // this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});

            let payload = {};
            payload["instance_ids"] = instance_ids;
            payload["region"] = this.region;
            payload["account"] = this.account;
            console.log(payload);
            const path = API_URL + "/instances/stop";
            axios
                .post(path, payload, {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res.status);
                    console.log(res.data);
                    this.getInstances();
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });

        },
        instanceStateClass(data) {
            return [
                {
                    'isstopped': data.state === "stopped",
                    'isother': data.state !== "stopped" && data.state !== "running",
                    'isrunning': data.state === "running"
                }
            ];
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
    }
})
</script>

<style lang="scss" scoped>

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

.isstopped {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
}

.isother {
    font-weight: 700;
    color: #FFA726;
}

.isrunning {
    font-weight: 700;
    color: #66BB6A;
}

::v-deep .p-datatable-header {
  background-color: transparent !important;
  border-width: 0 0 0 0 !important;
//   display: flex; // this smushes the Search box to the left, not what we want
}

::v-deep .table-header-class,
::v-deep .p-treetable-tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.01) !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr {
  background-color: rgba(40, 44, 48, 0.3);
//   border: 1px solid #FFF !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
}

</style>
