import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";

Vue.use(Vuex);

export default new Vuex.Store({
  /**
   * https://vuex.vuejs.org/guide/strict.html
   */

  strict: process.env.NODE_ENV !== "production",

  /**
   * // https://vuex.vuejs.org/guide/modules.html
   */
  modules: {
    authentication,
  },
});
