import axios, { AxiosResponse } from "axios";
import { Commit } from "vuex";
import { Auth } from 'aws-amplify';

export interface AuthState {
  isAuthenticated: boolean;
  username: string | null;
}

const state: AuthState = {
  isAuthenticated: false,
  username: null,
};

let interceptor: number | null = null;

export interface UserCredentials {
  username: string;
  password: string;
}

interface LoginResponse extends AxiosResponse {
  error?: string;
}
const actions = {
  async login(
    { commit }: { commit: Commit },
    auth: UserCredentials
  ): Promise<any> { // <null | string>
    let error = null;

    try {
        console.log("await Auth.signIn...")
        const user = await Auth.signIn(auth.username, auth.password);
        console.log('await signIn complete, user', user);
        // alert('Successfully logged in');

        // username from the Auth.signIn is really a Cognito user id,
        // so we'll save the email address which is what the user enters
        // when logging in
        commit("loginUser", auth.username);
        interceptor = axios.interceptors.request.use(function (config) {
          config.auth = {
            username: auth.username,
            password: auth.password,
          };
          return config;
        });
    } catch (err) {
        console.log(err);
        alert(err);//error.message);
        error = err;
    }
    return error;
    
    // await axios
    //   .post("http://localhost:5000/auth", auth) // TEE "/es-cloud-deployer-vue/auth"
    //   .then((data: LoginResponse) => {
    //     if (data && data.error) {
    //       error = data.error;
    //     } else {
    //       commit("loginUser", auth.username);
    //       interceptor = axios.interceptors.request.use(function (config) {
    //         config.auth = {
    //           username: auth.username,
    //           password: auth.password,
    //         };
    //         return config;
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //       error = err.response.data.error
    //         ? err.response.data.error
    //         : err.response.data;
    //     } else {
    //       error = err;
    //     }
    //   });
    // return error;
  },

  async logout({ commit }: { commit: Commit }): Promise<void> {
    commit("logoutUser");


    const user = await Auth.signOut();
    // alert('Successfully logged out');
    state.username = null;


    if (interceptor !== null) {
      axios.interceptors.request.eject(interceptor);
    }
  },
};

const mutations = {
  loginUser(state: AuthState, username: string): void {
    console.log("loginUser setting username", username);
    state.username = username;
    state.isAuthenticated = true;
  },
  logoutUser(state: AuthState): void {
    state.username = null;
    state.isAuthenticated = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
