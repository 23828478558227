import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import messages from "@/lang/messages";

// from program with Erik
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

import awsmobile from './aws-exports';
Amplify.configure(awsmobile);
Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
