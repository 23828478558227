

















import Vue from "vue";
import SidebarNavigation from "@EditShareCo/es-sidebar-navigation";
import type { NavItem, Logo } from "@EditShareCo/es-sidebar-navigation";

type DataType = {
  appNavList: Array<NavItem>;
  logo: Logo;
};

export default Vue.extend({
  name: "Main",
  components: {
    SidebarNavigation,
  },
  data(): DataType {
    return {
      appNavList: [
        {
          name: "Deployment",
          path: "/deployer",
          icon: "monitor",
        },
        {
          name: "Instances",
          path: "/instances",
          icon: "view-list",
        },
        {
          name: "Example 2",
          path: "/example2",
          icon: "view-list",
        },
        {
          name: "Logout",
          path: "/logout",
          icon: "",
        },
      ],
      logo: {
        src: require("@/assets/logo-mark.png"),
        alt: "EditShare",
      },
    };
  },
});
