export default {
  en: {
    username: "Username",
    password: "Password",
    login: "Login",
    logout: "Logout",
    welcome_message: "Welcome to es-cloud-deployer-vue",
    example_message: "This is another example",
  },
};
