





















import Vue from "vue";
import EsLoginForm from "@EditShareCo/es-login-form";

type DataType = {
  serverError: string | null;
};

export default Vue.extend({
  name: "Login",
  components: { EsLoginForm },
  data(): DataType {
    return {
      serverError: null,
    };
  },
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
  },
  methods: {
    async login(credentials: {
      username: string;
      password: string;
    }): Promise<void> {
      let error = await this.$store.dispatch(
        "authentication/login",
        credentials
      );
      if (error) {
        this.serverError = error;
      } else this.$router.push("/");
    },
    credentialsChanged(): void {
      this.serverError = null;
    },
  },
});
